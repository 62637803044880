<template>
  <div>
      <img id="presentacion" class="n_fondo" src="../assets/presentacion_pagina.png">
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

#presentacion {
       width: 1200px;
       height: 700px; 
}
</style>